@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Press+Start+2P&display=swap');

:root {
  --neon-green: #39FF14;
  --neon-purple: #9D00FF;
  --neon-blue: #00FFFF;
  --neon-pink: #FF00FF;
}

body {
  font-family: 'Press Start 2P', cursive;
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

.background-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(45deg, #ff00ff, #00ffff, #ff00ff);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}


.full-width-card {
  grid-column: 1 / -1; /* This makes the card span all columns */
  background: linear-gradient(45deg, #1e1e1e, #2a2a2a);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  margin-top: 30px;
  overflow: hidden;
}

.full-width-card iframe {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(157, 0, 255, 0.5);
}

.content-wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
}

.neon-sign {
  text-align: center;
  margin-bottom: 40px;
}

.neon-sign h1 {
  font-family: 'Permanent Marker', cursive;
  font-size: 3em;
  color: var(--neon-green);
  text-shadow: 
    0 0 5px var(--neon-green),
    0 0 10px var(--neon-green),
    0 0 20px var(--neon-green),
    0 0 40px var(--neon-green);
  animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    text-shadow: 
      0 0 5px var(--neon-green),
      0 0 10px var(--neon-green),
      0 0 20px var(--neon-green),
      0 0 40px var(--neon-green);
  }
  20%, 24%, 55% {       
    text-shadow: none;
  }
}

.intro-text {
  text-align: center;
  font-size: 1em;
  margin-bottom: 40px;
  animation: glitch 1s linear infinite;
}

@keyframes glitch {
  2%, 64% {
    transform: translate(2px,0) skew(0deg);
  }
  4%, 60% {
    transform: translate(-2px,0) skew(0deg);
  }
  62% {
    transform: translate(0,0) skew(5deg); 
  }
}

.adventure-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.adventure-card {
  background: linear-gradient(45deg, #1e1e1e, #2a2a2a);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.adventure-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
  transform: rotate(30deg);
  transition: transform 0.5s ease;
}

.adventure-card:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.adventure-card:hover::before {
  transform: rotate(0deg);
}

.adventure-card h2 {
  color: var(--neon-purple);
  margin-top: 0;
  font-size: 1.5em;
  text-shadow: 0 0 5px var(--neon-purple);
}

.adventure-card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0 0 10px rgba(157, 0, 255, 0.5);
}

.crypto-price {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--neon-blue);
  margin: 15px 0;
  text-shadow: 0 0 5px var(--neon-blue);
}

.btn {
  background-color: var(--neon-pink);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1em;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
}

.btn::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
  transform: scale(0);
  transition: transform 0.3s ease-out;
}

.btn:hover {
  background-color: #ff40ff;
  transform: scale(1.05);
  box-shadow: 0 0 15px var(--neon-pink);
}

.btn:hover::after {
  transform: scale(1);
}

.disclaimer {
  text-align: center;
  margin-top: 40px;
  font-size: 0.7em;
  color: #888;
  padding: 10px;
  border-top: 1px solid #444;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

/* New styles from App.css */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}